@import '../node_modules/starterkit/assets/src/scss/mixins';
@import '../node_modules/starterkit/assets/src/scss/configs/breakpoints-mappy';
@import '../node_modules/starterkit/assets/src/scss/grid/grid';
@import './stylesheets/color-palette';
@import './stylesheets/globals';

// creates 12 column Grid (Flexbox fallback) with breakpoints
@include creategrid($gutter: 16);

@font-face {
  font-family: 'DIN';
  src: local('DIN'), url(./fonts/DIN.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: local('AvenirNext'), url(./fonts/AvenirNext.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('./fonts/Geomanist-Bold.woff2') format('woff2'),
    url('./fonts/Geomanist-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('./fonts/Geomanist.woff2') format('woff2'),
    url('./fonts/Geomanist.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

$blue: #00205a;

// sections
.row .column {
  text-align: left;
}

.jb-login{
  background: #0084d4;
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h4{
    width: 100%;
    display: inline-block;
    text-align: center;
    color: white;
    font-size: 1.5rem;
  }
  div{
    width: 100%;
    justify-content: center;
    display: flex;
  }
  input, button{
    width: 360px;
    border: none;
    padding: 1rem;
    font-size: 1.2rem;
    margin: .5rem 0;
    text-align: center;
    color: white;
    display: inline-block;
  }
  input{
    background: rgba(white, .4);
  }
  button{
    background: darken(#0084d4, 30%);
    font-weight: bold;
  }
}

@import './stylesheets/heroSection';
@import './stylesheets/kindDesign';
@import './stylesheets/kindFoundation';
@import './stylesheets/colorSmear';
@import './stylesheets/kindWords';
@import './stylesheets/kindAtomic';
@import './stylesheets/booking';
@import './stylesheets//websiteSamples';
@import './stylesheets/masonrySection';
@import './stylesheets/results';
@import './stylesheets/footer';
@import './stylesheets/dayOfTravel';
@import './stylesheets/kindnessInAction';
