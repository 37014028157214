.kind-is-good {
  padding: 52px 0;
  @include bp(medium) {
    padding: 104px 0 208px 0;
  }

  &--wrapper {
    // padding-top: 54px;
    // border-top: solid 1px #d3d3d3;
    padding-bottom: 43px;

    @include bp(medium) {
      // padding-top: 208px;
      padding-bottom: 104px;
    }
  }

  &--header {
    font-family: "DIN";
    @include font-size(50px);
    font-weight: normal;
    line-height: 1;
    color: #011741;
    margin: 0;

    margin-bottom: 36px;

    @include bp(medium) {
      margin-bottom: 0;
    }
  }

  &--desc {
    margin: 0;
    font-family: AvenirNext;
    @include font-size(20px);
    font-weight: normal;
    line-height: 1.3;
    letter-spacing: 1px;
    color: #000000;
  }

  &--quote {
    position: relative;
    margin-bottom: 0px;

    .quote {
      position: relative;
      font-family: "DIN";
      color: #011741;
      @include font-size(38px);
      margin: 0;
      line-height: 1;
      padding-left: 20px;

      @include bp(medium) {
        @include font-size(100px);
        padding-left: 35px;
      }

      &::before {
        content: "\201C";
        font-family: Georgia, "Bitstream Charter", serif;
        position: absolute;
        top: 0;
        left: 0px;
      }

      &::after {
        position: relative;
        display: inline-block;
        vertical-align: sub;
        line-height: 1;
        transform: translateY(48%);
        content: "\201D";
        font-family: Georgia, "Bitstream Charter", serif;
      }
    }
  }

  &--quotename {
    margin: 50px 0 0 0;
    position: relative;
  }
}

.results {
  padding: 0 0 208px 0;

  &--wrapper {
    position: relative;
    padding-top: 104px;
    border-top: solid 1px #d3d3d3;

    @include bp(medium) {
      padding-bottom: 104px;
      padding-top: 104px;
    }
  }

  &--firstrow {
    position: relative;

    @include bp(medium) {
      padding-bottom: 104px;
    }
  }

  &--title {
    font-family: "AvenirNext";
    @include font-size(16px);
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.8px;
    margin: 0;
    color: #000;
  }

  &--stat-trigger-1,
  &--stat-trigger-2 {
    position: absolute;
    bottom: 50%;
  }

  &--stat-number {
    position: relative;
    display: inline-block;
    font-family: "DIN";
    line-height: 0.92;
    color: #011741;
    @include font-size(108px);

    @include bp(medium) {
      @include font-size(120px);
    }
  }

  &--stat-copy {
    margin: 0;
    @include font-size(14px);
    line-height: 1.73;
    letter-spacing: 0.75px;
    font-family: "AvenirNext";
    margin-top: 16px;
  }
}
