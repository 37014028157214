.kind-design {
  padding: 102px 0;

  .column {
    text-align: left;
  }

  &--titletrigger {
    position: absolute;
    top: 30vh;
  }

  &--trigger,
  &-pattern--trigger,
  &--getting-started--trigger {
    position: absolute;
    top: -50vh;
  }

  &--title {
    font-family: "DIN";
    font-size: 90px;
    line-height: 0.92;
    letter-spacing: -3.6px;
    color: #00205a;
    text-transform: capitalize;
    margin: 0;
    font-weight: normal;
    position: relative;

    @include bp(medium) {
      font-size: 120px;
    }
  }

  &--copy,
  &--cta {
    @include font-size(18px);
    line-height: 1.56;
    letter-spacing: 0.9px;
  }

  &--cta {
    display: block;
    margin-top: 55px;
  }

  &--video-wrapper {
    position: relative;
    display: block;
    // width: 100%;
    width: 70%;
    max-width: 1200px;
    height: 0;
    margin: 0 auto;
    // padding-bottom: 62.5%;
    padding-bottom: 45%;
    video {
      position: absolute;
      width: 100%;
      height: auto;
    }
  }

  &--mobile-wrapper {
    position: relative;
    display: block;
    width: 100%;
    max-width: 1200px;
    // height: 0;
    margin: 0 auto;
    padding-bottom: 15%;
    video {
      // position: absolute;
      width: 100%;
      height: auto;
    }
  }

  &--getting-started {
    padding: 0;

    .content-container {
      border-bottom: 1px solid #d3d3d3;
      padding-bottom: 208px;
    }
  }

  &--getting-started--title {
    @include font-size(16px);
    line-height: 1.63;
    letter-spacing: normal;
    color: #011741;
  }
}
