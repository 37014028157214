.kind-foundation {
  padding: 100px 0;

  &--title {
    font-family: 'DIN';
    @include font-size(50px);
    line-height: 1.2;
    letter-spacing: -1.25px;
    color: #00205a;
    margin: 0;
    font-weight: normal;
  }

  &--intro {
    margin-top: 24px;
  }
}
