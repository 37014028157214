.kind-atomic {
  overflow: hidden;
  &--hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 800px;
    background-size: cover;
    background-position: top center;
    z-index: 1;
  }

  &--shade {
    background: #00102d;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &--sprite-holder {
    position: absolute;
    height: 500px;
    transform: scale(1.25);
    overflow: hidden;
    top: 80px;
    @include bp(medium) {
      width: 600px;
      height: 540px;
      top: 80px;
      transform: scale(1.25);
    }
  }

  &--sprite {
    margin: 0 auto;
    position: relative;
    left: 0;
  }

  &--notch {
    position: relative;
    padding-top: 104px;
    margin-top: -104px;
    z-index: 2;
    background-color: white;
    padding-bottom: 208px;
  }

  &--title {
    font-family: "DIN";
    @include font-size(50px);
    margin: 0;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: -1.25px;
    color: #00205a;
  }
}

.kind-atomic-container {
  display: flex;
  margin-bottom: 442px;

  .plane-interior {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    .video-container {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .fullscreen-bg-video {
        min-width: 100%;
        min-height: 100%;

        /* Setting width & height to auto prevents the browser from stretching or squishing the video */
        width: auto;
        height: auto;

        /* Center the video */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .banner {
      width: 1260px;
      height: 260px;
      background: white;
      position: absolute;
      z-index: 1;
      bottom: -130px;
      left: 50%;
      transform: (translateX(-50%));
      display: flex;
      justify-content: space-around;

      .text-container {
        width: 100%;
        height: 50%;
        padding-top: 130px;
        display: flex;
        justify-content: center;
      }

      span {
        align-self: center;
        height: 100%;
        width: 50%;

        &:nth-child(1) {
          font-family: "DIN";
          font-size: 50px;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.63;
          letter-spacing: normal;
          color: #011741;
          text-align: center;
          width: 50%;
        }
      }
      .testimonial-container {
        width: 50%;
        position: relative;

        span {
          font-family: AvenirNext;
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: 0.9px;
          color: #000000;
          position: absolute;
          width: 515px;
          text-align: left;
        }
      }
    }
  }
}
