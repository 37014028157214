// circle colors:
// ==============
$circle-green: #6cc24a;
$circle-orange: #ff8200;
$circle-blue-one: #00205b;
$circle-blue-two: #0033a0;
$circle-blue-three: #0084d4;
$circle-blue-four: #00b5e2;
$circle-blue-five: #8dc8e8;
$circle-yellow: #e4cd4d;
$circle-red: #d50f0f;

$circle-width-height: 120px;

.kind-foundation-animation {
  overflow-x: hidden;
}

.kind-foundation--trigger {
  position: absolute;
  top: -50vh;
}

.smear-animation {
  position: relative;
  height: 100vh;
  width: 100%;
  // overflow: hidden;
  // top: 0 !important;

  &-holder {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }
}

.jb--plane {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -100%);
  transform-origin: center center;
  z-index: 3;
  opacity: 0;
}

.luggage-icon-copy {
  position: absolute;
  width: 100%;
  text-align: left;
  left: 50%;
  top: 450px;
  transform: translateX(-50%);
  z-index: 2;
  @include bp(medium) {
    top: 300px;
    width: 150%;
  }
}

.luggage-icons {
  position: absolute;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  @include bp(medium) {
    width: 83%;
  }

  height: auto;
  top: 118vh;

  &-icon {
    position: relative;
    display: flex;
    width: 48px;
    height: 48px;
    margin: 20px;
    vertical-align: top;
    align-items: center;
    justify-content: center;
  }
}

.luggage-holder {
  position: absolute;
  top: 120vh;
  left: 50%;
  width: 320px;
  opacity: 0;
  // height: 355px;
  transform: translate(-50%, -50%);
  @include bp(medium) {
    top: 148.5vh;
  }

  .luggage-icons-icon {
    position: absolute;
    display: flex;
    width: 48px;
    height: 48px;
    margin: 20px;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-94%, 11%);
    transform-origin: center center;
    transition: all 0.25s ease-in-out;
    //opacity: 0;

    $multiplierW: 20%;
    $multiplierH: 70px;

    &.partnerairline {
      left: calc(50% - #{$multiplierW * 0});
      top: calc(50% + #{$multiplierH * 1});
    }
    &.flight {
      left: calc(50% - #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 1});
    }
    &.flighthotels {
      left: calc(50% - #{$multiplierW * 2});
      top: calc(50% - #{$multiplierH * 3});
    }
    &.flying {
      left: calc(50% - #{$multiplierW * 1});
      top: calc(50% - #{$multiplierH * 3});
    }
    &.car {
      left: calc(50% - #{$multiplierW * 0});
      top: calc(50% - #{$multiplierH * 3});
    }
    &.home {
      left: calc(50% + #{$multiplierW * 1});
      top: calc(50% - #{$multiplierH * 3});
    }
    &.hotel {
      left: calc(50% + #{$multiplierW * 2});
      top: calc(50% - #{$multiplierH * 3});
    }
    &.app {
      left: calc(50% + #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 1});
    }
    &.rewards {
      left: calc(50% + #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 1});
    }

    &.sofly {
      left: calc(50% - #{$multiplierW * 2});
      top: calc(50% - #{$multiplierH * 1});
    }
    &.financial {
      left: calc(50% - #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 2});
    }
    &.cashplus {
      left: calc(50% - #{$multiplierW * 2});
      top: calc(50% - #{$multiplierH * 2});
    }
    &.shopping {
      left: calc(50% - #{$multiplierW * 1});
      top: calc(50% - #{$multiplierH * 1});
    }
    &.filter {
      left: calc(50% - #{$multiplierW * 0});
      top: calc(50% - #{$multiplierH * 2});
    }
    &.partners {
      left: calc(50% + #{$multiplierW * 1});
      top: calc(50% - #{$multiplierH * 2});
    }
    &.email {
      left: calc(50% + #{$multiplierW * 2});
      top: calc(50% - #{$multiplierH * 2});
    }
    &.save {
      left: calc(50% - #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 2});
    }
    &.card {
      left: calc(50% - #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 1});
    }
    &.bubble {
      left: calc(50% - #{$multiplierW * 1});
      top: calc(50% - #{$multiplierH * 2});
    }
    &.flyfi {
      left: calc(50% - #{$multiplierW * 2});
    }
    &.travelingtogether {
      left: calc(50% - #{$multiplierW * 1});
    }
    &.pooling {
      left: calc(50% + #{$multiplierW * 0});
      top: calc(50% - #{$multiplierH * 1});
    }
    &.bicycle {
      left: calc(50% + #{$multiplierW * 1});
      top: calc(50% - #{$multiplierH * 1});
    }
    &.trash {
      left: calc(50% + #{$multiplierW * 2});
      top: calc(50% - #{$multiplierH * 1});
    }
    &.deals {
      left: calc(50% + #{$multiplierW * 1});
      top: calc(50% - #{$multiplierH * 0});
    }
    &.claimsbook {
      left: calc(50% + #{$multiplierW * 2});
      top: calc(50% - #{$multiplierH * 0});
    }

    &.gift {
      left: calc(50% - #{$multiplierW * 0});
      top: calc(50% + #{$multiplierH * 2});
    }
    &.turkey {
      left: calc(50% + #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 2});
    }
    &.candles {
      left: calc(50% + #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 2});
    }
    &.see {
      left: calc(50% - #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 3});
    }
    &.eat {
      left: calc(50% - #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 3});
    }
    &.mint {
      left: calc(50% - #{$multiplierW * 0});
      top: calc(50% + #{$multiplierH * 3});
    }
    &.arrow {
      left: calc(50% + #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 3});
    }
    &.badges {
      left: calc(50% + #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 3});
    }
    &.crop {
      left: calc(50% - #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 4});
    }

    &.destinations {
      left: calc(50% - #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 4});
    }
    &.question {
      left: calc(50% - #{$multiplierW * 0});
      top: calc(50% + #{$multiplierH * 4});
    }
    &.start {
      left: calc(50% + #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 4});
    }
    &.close {
      left: calc(50% + #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 4});
    }
    &.map {
      left: calc(50% - #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 5});
    }
    &.redemption {
      left: calc(50% - #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 5});
    }
    &.accruel {
      left: calc(50% + #{$multiplierW * 1});
      top: calc(50% + #{$multiplierH * 5});
    }
    &.alert {
      left: calc(50% + #{$multiplierW * 2});
      top: calc(50% + #{$multiplierH * 5});
    }
    &.customerservice {
      left: calc(50% - #{$multiplierW * 0});
      top: calc(50% + #{$multiplierH * 5});
    }

    @include bp(900px) {
      $multiplierW: 30%;
      $multiplierH: 90px;

      &.partnerairline {
        left: calc(50% - #{$multiplierW * 4});
        top: calc(50% - #{$multiplierH * 2});
      }
      &.flight {
        left: calc(50% - #{$multiplierW * 3});
        top: calc(50% - #{$multiplierH * 2});
      }
      &.flighthotels {
        left: calc(50% - #{$multiplierW * 2});
        top: calc(50% - #{$multiplierH * 2});
      }
      &.flying {
        left: calc(50% - #{$multiplierW * 1});
        top: calc(50% - #{$multiplierH * 2});
      }
      &.car {
        left: calc(50% - #{$multiplierW * 0});
        top: calc(50% - #{$multiplierH * 2});
      }
      &.home {
        left: calc(50% + #{$multiplierW * 1});
        top: calc(50% - #{$multiplierH * 2});
      }
      &.hotel {
        left: calc(50% + #{$multiplierW * 2});
        top: calc(50% - #{$multiplierH * 2});
      }
      &.app {
        left: calc(50% + #{$multiplierW * 3});
        top: calc(50% - #{$multiplierH * 2});
      }
      &.rewards {
        left: calc(50% + #{$multiplierW * 4});
        top: calc(50% - #{$multiplierH * 2});
      }

      &.card {
        left: calc(50% - #{$multiplierW * 4});
        top: 50%;
      }
      &.financial {
        left: calc(50% - #{$multiplierW * 3});
        top: calc(50% - #{$multiplierH * 1});
      }
      &.cashplus {
        left: calc(50% - #{$multiplierW * 2});
        top: calc(50% - #{$multiplierH * 1});
      }
      &.shopping {
        left: calc(50% - #{$multiplierW * 1});
        top: calc(50% - #{$multiplierH * 1});
      }
      &.filter {
        left: calc(50% - #{$multiplierW * 0});
        top: calc(50% - #{$multiplierH * 1});
      }
      &.partners {
        left: calc(50% + #{$multiplierW * 1});
        top: 50%;
      }
      &.email {
        left: calc(50% + #{$multiplierW * 2});
        top: 50%;
      }
      &.save {
        left: calc(50% + #{$multiplierW * 3});
        top: 50%;
      }
      &.claimsbook {
        left: calc(50% + #{$multiplierW * 4});
        top: calc(50% - #{$multiplierH * 1});
      }

      &.sofly {
        left: calc(50% - #{$multiplierW * 4});
        top: calc(50% - #{$multiplierH * 1});
      }
      &.bubble {
        left: calc(50% - #{$multiplierW * 3});
        top: 50%;
      }
      &.flyfi {
        left: calc(50% - #{$multiplierW * 2});
      }
      &.travelingtogether {
        left: calc(50% - #{$multiplierW * 1});
      }
      &.pooling {
        left: calc(50% + #{$multiplierW * 1});
        top: calc(50% - #{$multiplierH * 1});
      }
      &.bicycle {
        left: calc(50% + #{$multiplierW * 2});
        top: calc(50% - #{$multiplierH * 1});
      }
      &.trash {
        left: calc(50% + #{$multiplierW * 3});
        top: calc(50% - #{$multiplierH * 1});
      }
      &.deals {
        left: calc(50% + #{$multiplierW * 4});
      }

      &.gift {
        left: calc(50% - #{$multiplierW * 4});
        top: calc(50% + #{$multiplierH * 1});
      }
      &.turkey {
        left: calc(50% - #{$multiplierW * 3});
        top: calc(50% + #{$multiplierH * 1});
      }
      &.candles {
        left: calc(50% - #{$multiplierW * 2});
        top: calc(50% + #{$multiplierH * 1});
      }
      &.see {
        left: calc(50% - #{$multiplierW * 1});
        top: calc(50% + #{$multiplierH * 1});
      }
      &.eat {
        left: calc(50% - #{$multiplierW * 0});
        top: calc(50% + #{$multiplierH * 1});
      }
      &.mint {
        left: calc(50% + #{$multiplierW * 1});
        top: calc(50% + #{$multiplierH * 1});
      }
      &.arrow {
        left: calc(50% + #{$multiplierW * 2});
        top: calc(50% + #{$multiplierH * 1});
      }
      &.badges {
        left: calc(50% + #{$multiplierW * 3});
        top: calc(50% + #{$multiplierH * 1});
      }
      &.crop {
        left: calc(50% + #{$multiplierW * 4});
        top: calc(50% + #{$multiplierH * 1});
      }

      &.destinations {
        left: calc(50% - #{$multiplierW * 4});
        top: calc(50% + #{$multiplierH * 2});
      }
      &.question {
        left: calc(50% - #{$multiplierW * 3});
        top: calc(50% + #{$multiplierH * 2});
      }
      &.start {
        left: calc(50% - #{$multiplierW * 2});
        top: calc(50% + #{$multiplierH * 2});
      }
      &.close {
        left: calc(50% - #{$multiplierW * 1});
        top: calc(50% + #{$multiplierH * 2});
      }
      &.map {
        left: calc(50% - #{$multiplierW * 0});
        top: calc(50% + #{$multiplierH * 2});
      }
      &.redemption {
        left: calc(50% + #{$multiplierW * 1});
        top: calc(50% + #{$multiplierH * 2});
      }
      &.accruel {
        left: calc(50% + #{$multiplierW * 2});
        top: calc(50% + #{$multiplierH * 2});
      }
      &.alert {
        left: calc(50% + #{$multiplierW * 3});
        top: calc(50% + #{$multiplierH * 2});
      }
      &.customerservice {
        left: calc(50% + #{$multiplierW * 4});
        top: calc(50% + #{$multiplierH * 2});
      }
    }
  }
}

.luggage-frames {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2560px;
  height: 1440px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.luggage-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.circle-smear {
  position: relative;
  width: calc(100% / 9);
  height: auto;
  margin: 0 9px;
  transform: translate(0, 0);
  z-index: 1;
  pointer-events: none;
  transform-origin: center center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .circle {
    position: relative;
    width: 100%;
  }

  &.circleToSmear {
    .circle {
      height: 100%;
    }
  }

  .plane--marker {
    position: absolute;
    left: 50%;
    top: 25%;
    width: 100%;
    height: 1px;
    transform: translate(-50%, -50%);
  }

  &.circle-1 {
    .circle {
      > div {
        background-color: $circle-green;
      }
    }
  }

  &.circle-2 {
    .circle {
      > div {
        background-color: $circle-orange;
      }
    }
  }

  &.circle-3 {
    .circle {
      > div {
        background-color: $circle-blue-one;
      }
    }
  }

  &.circle-4 {
    .circle {
      > div {
        background-color: $circle-blue-two;
      }
    }
  }

  &.circle-5 {
    .circle-5--intro {
      position: absolute;
      width: 290px;
      text-align: center;
      transform: translateX(-50%) translateY(-180%);
      left: 50%;
      margin: 0;

      @include bp(medium large) {
        transform: translateX(-50%) translateY(-100%);
      }

      @include bp(large xlarge) {
        transform: translateX(-50%) translateY(-80%);
      }

      @include bp(xlarge) {
        transform: translateX(-50%) translateY(-30%);
      }
    }

    .circle-5--outro {
      position: absolute;
      width: 375px;
      text-align: center;
      transform: translateX(-50%) translateY(-180%);
      left: 50%;
      opacity: 0;
      visibility: hidden;
      margin: 0;
      @include bp(medium large) {
        transform: translateX(-50%) translateY(-100%);
      }

      @include bp(large xlarge) {
        transform: translateX(-50%) translateY(-80%);
      }

      @include bp(xlarge) {
        transform: translateX(-50%) translateY(-30%);
      }
    }

    z-index: 2;

    .circle {
      > div {
        background-color: $circle-blue-three;
      }
    }
  }

  &.circle-6 {
    .circle {
      > div {
        background-color: $circle-blue-four;
      }
    }
  }

  &.circle-7 {
    .circle {
      > div {
        background-color: $circle-blue-five;
      }
    }
  }

  &.circle-8 {
    .circle {
      > div {
        background-color: $circle-yellow;
      }
    }
  }

  &.circle-9 {
    .circle {
      > div {
        background-color: $circle-red;
      }
    }
  }

  &--top {
    position: relative;
    height: 0;
    border-radius: 50%;
    left: 0;
    top: 0;
    padding: 50% 0;
    width: 100%;
    transform: translateY(50%);
  }

  &--mid {
    position: relative;
    top: 0;
    width: 100%;
    height: 0;
    z-index: 1;
  }

  &--bottom {
    height: 0;
    border-radius: 50%;
    position: relative;
    left: 0;
    top: 0;
    padding: 50% 0;
    width: 100%;
    transform: translateY(-50%);
  }
}
