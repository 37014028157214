.kindness-in-action {
  position: relative;
  padding: 64px 0;
  overflow: hidden;
  @include bp(medium) {
    padding: 104px 0;
  }

  &--header {
    font-family: "DIN";
    @include font-size(50px);
    font-weight: normal;
    line-height: 1;
    color: #011741;
    margin: 0;

    margin-bottom: 36px;

    @include bp(medium) {
      margin-bottom: 0;
    }
  }

  &--desc {
    margin: 0;
    font-family: AvenirNext;
    @include font-size(16px);
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.8px;
    color: #000000;
  }

  &--carousel {
    position: relative;
    display: block;
    padding-top: 50px;
    padding-bottom: 120%;
    white-space: nowrap;
    width: 100vw;
    @include bp(medium) {
      padding-top: 100px;
      padding-bottom: 52%;
    }
  }

  &--trigger {
    position: absolute;
    top: 0px;
  }

  &--pin {
    position: relative;
  }

  .carousel-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
  }

  .carousel-item {
    position: relative;
    display: inline-block;
    margin-right: 17px;
    &:last-child {
      margin-right: 0;
    }

    &.one,
    &.two,
    &.three,
    &.four,
    &.five,
    &.seven {
      width: 400px;
      @include bp(medium) {
        width: 515px;
      }
    }

    &.six {
      width: 320px;
      @include bp(medium) {
        width: 412px;
      }
    }

    &--media {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
    }

    &--title {
      position: relative;
      width: 100%;
      white-space: normal;
      font-weight: bold;
    }

    &--copy {
      position: relative;
      width: 100%;
      white-space: normal;
    }
  }
}

.kindness-in-action-container {
  width: 1050px;
  margin: 0 auto;
  padding-top: 300px;
  height: 1300px;

  .header-container {
    width: 100%;
    height: 100px;
    display: flex;
    padding-bottom: 190px;

    .kindness-header {
      width: 50%;
      font-family: DIN;
      font-size: 50px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #011741;
      padding-bottom: 190px;
    }

    .subtext {
      width: 50%;
      font-family: AvenirNext;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.8px;
      color: #000000;
    }
  }

  .photography-carousel-container {
    height: 645px;
    position: absolute;
    display: flex;
    padding-bottom: 315px;

    .asset-container {
      width: 515px;
      display: flex;
      flex-direction: column;
      padding-right: 20px;

      img {
        width: 100%;
        padding-bottom: 25px;
      }

      span {
        font-family: AvenirNext;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.73;
        letter-spacing: 0.7px;
        color: #000000;
      }

      .title {
        font-weight: bold;
      }
    }
  }
}
