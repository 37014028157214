.new-feed {
  padding: 104px 0 208px 0;
  // background: linear-gradient(to bottom, #f2f5f6 90%, #fff 77%);
  background: #ebeaef;

  &--header {
    font-family: "DIN";
    @include font-size(50px);
    font-weight: normal;
    line-height: 1;
    color: #011741;
    margin: 0;

    margin-bottom: 36px;

    @include bp(medium) {
      margin-bottom: 0;
    }
  }

  &--desc {
    margin: 0;
    font-family: AvenirNext;
    @include font-size(16px);
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.8px;
    color: #000000;
  }

  &--post {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 48px;
  }

  &--post-header {
    position: relative;
    width: 100%;
    margin-bottom: -3px;
  }

  &--post-video {
    position: relative;
    width: 100%;
  }

  &--masonry {
    position: relative;
    padding-top: 148px;
  }

  @include bp(medium) {
    &--set1 {
      padding-top: 82%;
    }

    &--set3 {
      padding-top: 55%;
    }

    &--set4 {
      padding-top: 23%;
    }
  }
}

.masonry-section-container {
  position: relative;
  width: 100%;
  height: 1750px;
  background-color: #f2f5f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 535px;

  .header-container {
    display: flex;
    width: 1045px;
    align-items: flex-start;
    margin: 0 auto;
    justify-content: space-between;
    padding-top: 210px;

    .new-feed-header {
      width: 300px;
      height: 100px;
      font-family: DIN;
      font-size: 50px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #011741;
    }

    .subtext {
      width: 515px;
      font-family: AvenirNext;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.8px;
      color: #000000;
    }
  }

  .masonry-container {
    height: 1516px;
    width: 1256px;
    bottom: -210px;
    position: absolute;
    display: flex;

    .column {
      width: 302px;

      &:nth-of-type(1) {
        margin-right: 16px;
        padding-top: 256px;
        box-sizing: border-box;
      }

      &:nth-of-type(2) {
        margin-right: 16px;
      }

      &:nth-of-type(3) {
        margin-right: 16px;
        padding-top: 160px;
      }

      &:nth-of-type(4) {
        padding-top: 64px;
      }

      img {
        width: 100%;
      }
    }
  }
}
