@function text-stroke($size: 2, $color: #fff, $correction: 0) {
  $radius: $size - $correction;
  $stroke: ();

  @for $i from -$size through $size {
    @for $k from -$size through $size {
      $x: $k;
      $y: $i;

      @if $k > 0 {
        $x: $k - 0.5;
      } @else if $k < 0 {
        $x: $k + 0.5;
      }

      @if $i > 0 {
        $y: $i - 0.5;
      } @else if $i < 0 {
        $y: $i + 0.5;
      }

      @if ($x * $x + $y * $y <= $radius * $radius) {
        $stroke: append($stroke, $i * 1px $k * 1px 0 $color, comma);
      }
    }
  }

  @return $stroke;
}

@mixin text-stroke($size: 2, $color: #fff, $correction: 0) {
  text-shadow: text-stroke($size, $color, $correction);
}

// placeholders
// ===================
//
%relative-block {
  position: relative;
  display: block;
}

%absolute-block {
  position: absolute;
  display: block;
}

// variables
$layout-max-width: 1440px;
$layout-min-width: 320px;

// wrappers
// ===================
//
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: $black;
  background-color: transparent;
  //font-family: $font-standard;
  min-width: $layout-min-width;
  scroll-behavior: smooth;
  overflow-x: hidden;
  @include font-size(14px);
}

.hide {
  display: none;
  visibility: hidden;
  position: absolute;
}

section,
.section {
  display: block;
  position: relative;
  width: 100%;
}

.content-container {
  @extend %relative-block;
  margin: 0 auto;
  max-width: $layout-max-width;
  width: 100%;
  padding: 0 34px;
  transition: padding 0.25s ease;
  z-index: 2;

  @include bp(large) {
    padding: 0 90px;
  }
}

p,
a {
  font-family: AvenirNext;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #000000;
  margin-bottom: 0;
}

a {
  text-decoration: underline;
  color: #0084d4;
}
