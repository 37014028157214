// Color Palette
// -------------
// - Primary
// - Secondary
// - Links
// - Misc

// Primary
$black: #000;
$white: #fff;


// Secondary
$off-white: #f5f5f5;
$gray-light: #eaeaea;
$gray-medium: #d4d4d4;

$gray-dark: #736e7d;
$gray-semi-dark: #979797;
$gray-charcoal: #736e7e;
$almost-black: #333;

//error red
$red: #d90909;

//confirmation green
$green: #32a02d;

// Misc
$modal-background: rgba($black, .8);
