@import "../../node_modules/starterkit/assets/src/scss/mixins";

.hero {
  width: 100%;
  height: 100vh;
  background: grey;
  position: relative;
  display: block;

  .hero-into--trigger {
    position: absolute;
    top: 51%;
  }

  .content-container {
    height: 100%;
    padding-top: 32px;
  }

  .bss-logo-container {
    position: relative;
    z-index: 1;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @include bp(medium) {
    }
  }

  &-logo {
    > .column {
      @include ie-styles {
        align-items: flex-end;
      }
    }
  }

  &-intro {
    height: calc(100% - 78px);

    > .column {
      place-self: center stretch;

      @include ie-styles {
        justify-content: center;
      }
    }
  }

  &-intro--logo {
    flex-grow: 1;
    width: 60px;
    @include bp(medium) {
      width: 100px;
    }
  }
  .hero-intro--bsslogo {
    width: 150px;
    height: 27px;
    flex-grow: 1;
    @include bp(medium) {
      width: 200px;
      height: 27px;
    }
  }
  .hero-intro--xmark {
    flex-grow: 1;
    fill: #fff;
    margin: 0 10px;
    width: 12px;
    height: 12px;
    @include bp(medium) {
      margin: 0 10px;
    }
  }

  .video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .fullscreen-bg-video {
      min-width: 100%;
      min-height: 100%;

      /* Setting width & height to auto prevents the browser from stretching or squishing the video */
      width: auto;
      height: auto;

      /* Center the video */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.1);
  }

  .hero-text-container {
    width: 100%;
    height: 240px;
    font-family: "DIN";
    font-size: 18vw;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    line-height: 1;
    letter-spacing: -3.8px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    position: relative;
    justify-content: left;
    margin-top: -21px;

    @include bp(medium) {
      font-size: 7.5vw;
      flex-direction: row;
    }

    @include bp(xlarge) {
      @include font-size(110px);
    }

    .text-container {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: column;

      @include bp(medium) {
        flex-direction: row;
        align-items: flex-end;
      }

      .make-planning-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-right: 30px;
        width: auto;
        @include bp(medium) {
          align-items: flex-end;
        }
      }

      .rolodex-container {
        display: block;
        width: 100%;
        height: 0;
        padding-top: 25%;
        //overflow: hidden;
        position: relative;

        @include bp(medium) {
          padding-top: 15%;
        }

        @include bp(large) {
          width: 466px;
        }

        span {
          position: absolute;
          bottom: 0px;
        }
      }
    }
  }
}
