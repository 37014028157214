.footer {
  position: relative;
  display: block;
  background-color: #00205b;
  padding-top: 88px;
  color: white;

  .tos {
    position: relative;
    margin-top: 146px;
    padding-bottom: 26px;

    .column {
      display: flex;
      align-items: flex-end;
    }
  }

  p {
    font-family: "Geomanist";
    color: white;
    margin: 0;
  }

  &-subhead {
    text-transform: uppercase;
    color: white;
    @include font-size(14px);

    font-weight: 700;
    margin-top: 0;
  }

  a {
    color: white;
    text-decoration: none;
    font-family: "Geomanist";
    font-weight: 400;
    &:hover {
      opacity: 0.75;
    }
  }

  a.footer-small-copy {
    font-weight: 700;
  }

  &-small-copy {
    position: relative;
    @include font-size(11px);
    text-transform: uppercase;
    font-weight: 700;
    line-height: 2.2;
    // letter-spacing: .5px;

    span {
      display: block;
      @include font-size(23px);
      line-height: 1;
    }

    &.tagline {
      text-align: center;
      // width: 100%;
    }

    &[href] {
      position: relative;
      margin-left: 5px;
    }
  }

  &-divider {
    padding-top: 52px;
    border-bottom: 1px solid rgba(white, 0.4);
    margin-bottom: 33px;
  }
}

.footer-container {
  height: 382px;
  background-color: #00205b;
  padding: 88px 196px 30px;
  display: flex;
  flex-direction: column;

  .big-spaceship-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: Geomanist;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: 0.7px;
    color: #ffffff;
    position: relative;
    margin-bottom: 96px;

    .emails {
      display: flex;
      flex-direction: column;

      span:nth-of-type(1) {
        text-transform: uppercase;
      }
    }

    &::after {
      content: "";
      width: 100%;
      position: absolute;
      height: 1px;
      background-color: white;
      bottom: -52px;
    }
  }

  .social-link-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 145px;

    .address-container {
      display: flex;
      flex-direction: column;
      font-family: Geomanist;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: 0.7px;
      color: #ffffff;

      span:nth-of-type(1) {
        font-weight: bold;
      }
    }
  }

  .footer-terms-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;

    .copyright-container {
      width: 249px;
      height: 22px;
      font-family: Geomanist;
      font-size: 10px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.2;
      letter-spacing: 0.5px;
      color: #ffffff;
      text-transform: uppercase;

      span:nth-of-type(1) {
        padding-right: 10px;
      }
    }

    .big-spaceship-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Geomanist;
      font-size: 10px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.9;
      letter-spacing: 0.7px;
      text-align: center;
      color: #ffffff;
      width: 249px;
      text-transform: uppercase;

      span:nth-of-type(1) {
        font-size: 23px;
        line-height: 1;
        font-weight: normal;
      }
    }

    .terms {
      width: 249px;
      font-family: Geomanist;
      font-size: 10px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.2;
      letter-spacing: 0.5px;
      color: #ffffff;
      text-transform: uppercase;
      display: flex;
      justify-content: flex-end;

      span:nth-of-type(1) {
        padding-right: 10px;
      }
    }
  }
}
.bss-logo-container-footer {
  width: 160px;
  margin: 0 auto;
  svg {
    width: 100%;
  }
}

.footerContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 160px 0 40px;
  @include bp(medium) {
    flex-direction: row;
  }
}

.footer-small-copy {
  flex: 1;
  text-align: center;
  align-self: center;
}

.footerLogoContainer {
  flex: 1;
  align-self: center;
  order: 1;
  @include bp(medium) {
    order: 2;
  }
}

.footer-copyright {
  order: 2;
  padding: 10px 0;
  @include bp(medium) {
    order: 1;
    padding: 0;
  }
}

.privacy-link {
  order: 3;
  @include bp(medium) {
    order: 3;
  }
}
