.websites {
  margin: 0 0 104px 0;
  &--header {
    font-family: "DIN";
    @include font-size(50px);
    font-weight: normal;
    line-height: 1;
    color: #011741;
    margin: 0 0 36px 0;

    @include bp(medium) {
      margin-bottom: 0;
    }
  }

  &--desktop {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
  }
}
.website-samples-container {
  width: 100%;
  position: relative;
  background-color: rgba(234, 238, 241, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-container {
    padding: 315px 0 210px 0;
    display: flex;
    max-width: 1045px;
    align-items: flex-start;
    margin: 0 auto;

    .header-text {
      width: 50%;
      height: 100%;
      font-family: DIN;
      font-size: 50px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: -1.3px;
      color: #00205a;
      padding-right: 75px;
    }

    .description-text {
      width: 515px;
      height: 81px;
      font-family: AvenirNext;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: 0.8px;
      color: #000000;
    }
  }

  img {
    width: 1047px;
  }

  .sticky-container {
    width: 1045px;
    height: 100%;
    padding-top: 300px;
    display: flex;
    position: relative;

    .number-container {
      display: flex;
      height: 300px;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 100px;
      width: 50%;
      position: sticky;

      .number {
        display: flex;
        font-family: AvenirNext;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.88;
        letter-spacing: -0.1px;
        color: #cfcfcf;

        .active {
          color: #00205a;
        }

        span {
          &:nth-child(1) {
            padding-right: 15px;
          }

          &:nth-child(2) {
            font-family: AvenirNext;
            font-size: 15px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.6;
            letter-spacing: 0.7px;
            color: #000000;
          }
        }
      }
    }

    .checkin-asset-container {
      width: 50%;
      position: relative;
      img {
        position: absolute;
        width: 100%;
      }
    }
  }
}
