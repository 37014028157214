.booking {
  position: relative;
  padding: 0;

  &--trigger1 {
    position: relative;
  }

  &-desktop--cover {
    position: absolute;
    width: 100%;
    height: 104px;
    background-color: #ebeaef;
    z-index: 3;
    top: 0;
    left: 0;
  }

  &-desktop {
    padding-top: 104px;
    // height: calc(90vh);
    background: #ebeaef;
  }
  &-homepage-sticky-nav {
    // position: absolute;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: #201e5a;

    img {
      position: relative;
      width: 100%;
    }
  }

  .shorterColumn {
    height: 70%;
  }

  &-homepage {
    // position: absolute;
    top: -92px;
    position: relative;
    // left: 0;
    display: block;
    width: 100%;
    height: auto;
    z-index: 1;
  }

  &-mobile {
    padding: 104px 0;
  }

  &-mobile--holder {
    position: relative;
    max-width: 515px;
    margin: 0 auto;

    &.right {
      padding-top: 64px;
      @include bp(medium) {
        padding-top: 300px;
      }
    }
  }

  &-image {
    position: relative;
    width: 100%;
    height: auto;
  }

  &-mobile--copy {
    span {
      display: block;
      font-weight: bold;
    }
  }
}
