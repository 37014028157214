// colors:
$white: #fff;
$offwhite: #ebeaef;
$black: #000;
$blue-dark: #00205b;
$blue-light: #0033a0;

.day-of-travel {
  position: relative;
  background: linear-gradient(to bottom, $offwhite, $offwhite);
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  &.skyone {
    background: linear-gradient(to bottom, #4e83b0, #52bafa);
  }

  &.skytwo {
    background: linear-gradient(to bottom, #e0873c, #52bafa);
  }

  &.skythree {
    background: linear-gradient(to bottom, #011f5e, #01339d);
  }

  &.skyfour {
    background: linear-gradient(to bottom, #1f5288, #2c75ca);
  }

  &.skyfive {
    background: linear-gradient(
      to bottom,
      #3d84b1 10%,
      #54b7f4 55%,
      #d2b64d 90%
    );
  }

  .dot-header {
    display: none;
    @include bp(medium) {
      position: absolute;
      top: 15vh;
      display: block;
    }
  }

  &--title {
    @include font-size(50px);
    font-weight: normal;
    font-family: "DIN";
    margin-top: 0;
    margin-bottom: 104px;
    color: $blue;
    line-height: 1;
    letter-spacing: -1.3px;

    &.skyone {
      color: $white;
    }
  }

  &--point {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: custom-counter;
    color: $blue;

    &.skyone {
      color: $white;
    }

    li {
      position: relative;
      counter-increment: my-awesome-counter;
      padding-left: 32px;
      margin-bottom: 102px;

      &.active {
        &::before {
          opacity: 1;
        }

        p {
          opacity: 1;
        }
      }

      &::before {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        content: "0" counter(my-awesome-counter);
        font-weight: bold;
        @include font-size(16px);
        line-height: 1.5;
        letter-spacing: -0.13px;
        opacity: 0.25;
        transition: opacity 0.3s ease;
      }
    }

    p {
      @include font-size(15px);
      line-height: 1.6;
      letter-spacing: 0.75px;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    @include bp(medium) {
      padding-right: 0px;
    }
  }

  &--replay {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    border: 0;
    border-radius: 10px;
    padding: 7px 10px;
    background: #00205b;
    color: #fff;
    font-size: 0.7rem;
    outline: none;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    bottom: 75px;
  }
}

// placeholder
%animate-me {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  transform-origin: center center;
}

.phone {
  @extend %animate-me;
  width: 454px;
  height: 796px;
}

.boarding-pass-bottom-icons {
  position: absolute;
  width: 280px;
  height: 65px;
  bottom: 185px;
  left: 85px;
  z-index: 3;
}
.icon-manageTrip {
  position: absolute;
  width: 44px;
  height: 61px;
  left: 26px;
  top: 0;
}
.icon-scheduleLyft {
  position: absolute;
  width: 50px;
  height: 61px;
  left: 114px;
  top: 0;
}
.icon-tripChecklist {
  position: absolute;
  width: 47px;
  height: 61px;
  left: 206px;
  top: 0;
}
.icon-startWatching {
  position: absolute;
  width: 49px;
  height: 61px;
  left: 114px;
  top: 0;
  opacity: 0;
}
.icon-boardingPassIcon {
  position: absolute;
  width: 49px;
  height: 61px;
  left: 205px;
  top: 0;
  opacity: 0;
}
.icon-continueWatching {
  position: absolute;
  width: 50px;
  height: 63px;
  left: 24px;
  top: 0;
  opacity: 0;
}
.icon-snackDrinks {
  position: absolute;
  width: 44px;
  height: 65px;
  left: 117px;
  top: 0;
  opacity: 0;
}
.icon-flightMap {
  position: absolute;
  width: 30px;
  height: 64px;
  left: 217px;
  top: 0;
  opacity: 0;
}

.boarding-pass {
  @extend %animate-me;
  border-radius: 6px;
  background-color: $offwhite;
  box-shadow: 0 0 10px rgba($black, 0.1);
  top: calc(50% + 117px);
  width: 280px;
  height: 233px;
  z-index: 2;

  .boarding-flight-num {
    position: absolute;
    left: 50%;
    top: 20px;
    z-index: 2;
    transform: translateX(-50%);
    opacity: 0;
    width: 90%;
  }

  .boarding-headline,
  .boarding-headline2 {
    position: absolute;
    width: 100%;
    height: auto;
    top: 55px;
    overflow: hidden;
    opacity: 0;

    .gradient {
      position: absolute;
      width: 200%;
      height: 100%;
      z-index: 2;
      left: 0;
      top: 0;
      transform: translateX(-50%);
      background: linear-gradient(to right, rgba(white, 0) 45%, white 50%);
    }

    .head {
      position: relative;
      z-index: 1;
      left: 13px;
    }
  }

  .boarding-headline2 {
    z-index: 2;
    background-color: white;
    padding-bottom: 20px;
  }

  .boarding-flight-terminal {
    position: absolute;
    left: 13px;
    top: 200px;
    z-index: 2;
    opacity: 0;
  }

  .boarding-flight-gate,
  .boarding-flight-group,
  .boarding-flight-seat {
    position: absolute;
    left: 115px;
    top: 200px;
    z-index: 2;
    opacity: 0;
  }

  .boarding-flight-group {
    left: 87px;
  }

  .boarding-flight-seat {
    left: 161px;
  }

  // .boarding-pass-bottom-labels--cover {
  //   position: absolute;
  //   bottom: 25px;
  //   left: 110px;
  //   z-index: 2;
  //   width: 61px;
  //   height: 40px;
  //   background-color: #f6f6f6;
  //   opacity: 0;
  //   z-index: 2;
  // }

  // .boarding-pass-bottom-labels {
  //   position: absolute;
  //   bottom: -205px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   opacity: 0;
  // }

  .boarding-pass-bottom-chat {
    position: absolute;
    bottom: -40px;
    left: 230px;
    // transform: translateX(-50%);
    width: 45px;
    height: 45px;
    background-color: #00b5e2;
    border-radius: 45px;
    opacity: 0;
  }

  .icon-connectBtn {
    position: absolute;
    width: 238px;
    height: 56px;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .icon-wifiText {
    width: 220px;
    height: 33px;
    position: absolute;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
  }

  .icon-landTimeLong {
    width: 240px;
    height: 130px;
    position: absolute;
    top: 55px;
    left: 20px;
  }

  .icon-landTimeMid {
    width: 45px;
    height: 26px;
    position: absolute;
    top: 215px;
    left: 13px;
  }

  .icon-FlightNoDark {
    width: 70px;
    height: 12px;
    position: absolute;
    top: 20px;
    left: 22px;
  }

  .icon-downChevron {
    width: 16px;
    height: 8px;
    position: absolute;
    top: 21px;
    right: 21px;
  }

  .icon-chatCopy {
    width: 196px;
    height: 12px;
    position: absolute;
    top: 17px;
    right: 20px;
    opacity: 1;
  }

  .bording-pass-short {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  .top-half {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: 6px;
  }

  .boarding-pass-long {
    opacity: 0;
    height: 234px;
    width: 280px;
    position: relative;
  }

  .triangle {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    bottom: -5px;
    z-index: 2;
  }

  .triangle-left {
    border-width: 5px 0 5px 7px;
    border-color: transparent transparent transparent $blue-dark;
    left: 0;
  }

  .triangle-right {
    border-width: 5px 7px 5px 0;
    border-color: transparent $blue-dark transparent transparent;
    right: 0;
  }

  .boarding-pass-darkMode {
    background: #00205b;
    width: 280px;
    height: 464px;
    position: absolute;
    top: -230px;
    left: 0;
    border-radius: 7px;
    opacity: 0;
  }

  .icon-welcomeTo {
    position: absolute;
    width: 223px;
    height: 72px;
    top: 55px;
    left: 20px;
    opacity: 0;
  }

  .icon-pointsEarned {
    position: absolute;
    width: 239px;
    height: 14px;
    top: 145px;
    left: 21px;
    opacity: 0;
  }

  .icon-rating {
    position: absolute;
    width: 186px;
    height: 47px;
    bottom: 20px;
    left: 47px;
    opacity: 0;
  }

  .icon-ratingFilled {
    width: 27px;
    height: 27px;
    top: 418px;
    position: absolute;
    opacity: 0;
    &.filled1 {
      left: 48px;
    }
    &.filled2 {
      left: 88px;
    }
    &.filled3 {
      left: 126px;
    }
    &.filled4 {
      left: 166px;
    }
    &.filled5 {
      left: 205px;
    }
  }

  .handWave {
    position: absolute;
    width: 140px;
    height: 95px;
    left: 50%;
    bottom: 200px;
    transform: translateX(-50%);
    .icon-handWave {
      width: 77px;
      height: 83px;
      position: absolute;
      bottom: 0;
      &.handL {
        transform: rotate(0deg);
        left: 0px;
        z-index: 2;
      }
      &.handR {
        transform: rotate(5deg);
        left: 10px;
        z-index: 2;
      }
    }
    .icon-clapsNumber {
      width: 55px;
      height: 55px;
      position: absolute;
      bottom: 30px;
      right: 0;
      z-index: 3;
    }
    .icon-clapEffect {
      width: 23px;
      height: 12px;
      position: absolute;
      top: 0;
      right: 70px;
      opacity: 0.5;
    }
  }

  .icon-landTextLarge {
    position: absolute;
    width: 235px;
    height: 122px;
    top: 48px;
    left: 20px;
    opacity: 0;
  }
}

.phone-screen {
  @extend %animate-me;

  width: 320px;
  height: 568px;
  z-index: 1;

  &.side-cover {
    z-index: 4;
    opacity: 0;

    .bar {
      position: absolute;
      right: 0;
      width: 16px;
      height: 60%;
      top: 50%;
      transform: translate(100%, -50%);
      background-color: $white;
    }
  }

  .statusBar {
    width: 280px;
    height: 55px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    opacity: 0;
    z-index: 3;
    .icon-airportCodes {
      width: 86px;
      height: 12px;
      left: 0;
      top: 50%;
      position: absolute;
      top: 30px;
    }
    .statusCircle {
      height: 8px;
      width: 8px;
      background-color: #6cc24a;
      border-radius: 50%;
      position: absolute;
      top: 32px;
      right: 58px;
    }
    .statusText {
      position: absolute;
      top: 30px;
      right: 0;
      .icon {
        position: absolute;
        right: 0;
      }
    }
    .icon-Ontime {
      width: 54px;
      height: 11px;
    }
    .icon-Taxiingtogate {
      width: 87px;
      height: 12px;
      opacity: 0;
    }
    .icon-Delayed {
      width: 55px;
      height: 14px;
      opacity: 0;
    }
    .icon-Baggageisready {
      width: 100px;
      height: 12px;
      opacity: 0;
    }
  }

  .phone-screen-status,
  .phone-screen-status2 {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 266px;
    z-index: 3;
    opacity: 0;
  }

  .phone-screen-topnav {
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 266px;
    z-index: 3;
    opacity: 0;
  }

  .phone-screen-nav {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 261px;
    z-index: 3;
    opacity: 0;
  }

  .phone-screen-color {
    background-color: $blue-dark;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .phoneFrame {
    position: absolute;
    width: 142%;
    transform: translate(-50%, calc(-50% + 35px));
    top: 50%;
    left: 50%;
    height: auto;
    z-index: 1;
  }
}

.ribbon {
  @extend %animate-me;
  width: 2036px;
  left: 150vw;
  //transform: translate(-13%, calc(0% + 85px));
  transform: translate(50vw, calc(-50% + 85px));
  z-index: 3;

  @include bp(medium) {
    transform: translate(-50%, calc(-50% + 85px));
  }

  .ribbon-image {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .ribbon-icons {
    position: absolute;
    left: 0;
    bottom: -38px;
    transform: translateX(-50%);
    opacity: 0;

    &.plane {
      left: 95px; //45
      opacity: 0;
    }

    &.car {
      left: 186px; //136
      opacity: 0;
    }

    &.checkmark {
      left: 277px; // 227
      bottom: -42px;
      opacity: 0;
    }
  }

  .ribbon-arrivalTime,
  .ribbon-arrivalTime2 {
    position: absolute;
    bottom: 56px;
    left: 174px;
    opacity: 0;
  }

  .ribbon-arrivalTime2 {
    left: 89px;
    opacity: 0;
  }

  .ribbon-userflight {
    position: absolute;
    bottom: 46px;
    left: 15px;
    opacity: 0;
  }

  .ribbon-path-s1 {
    border: 0 none;
    background: none;
    height: 0;
    width: 0;
    position: absolute;
    top: 60px;
    left: 44px;
    opacity: 0.5;
    &.dotted {
      border-top: 1px dashed #ffffff;
      opacity: 0;
    }
    &.line {
      border-top: 1px solid #ffffff;
      opacity: 0;
      width: 145px;
    }
  }

  .ribbon-path-s2 {
    border: 0 none;
    background: none;
    height: 0;
    width: 0;
    position: absolute;
    top: 60px;
    left: 35px;
    border-top: 1px dashed #ffffff;
    opacity: 0;
  }

  .ribbon-path-s3 {
    border: 0 none;
    background: none;
    height: 0;
    width: 0;
    position: absolute;
    top: 25px;
    &.dotted {
      left: 48px;
      border-top: 1px dashed #ffffff;
      opacity: 0.5;
      width: 140px;
    }
    &.line {
      left: -90px;
      border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
      opacity: 0;
      width: 108px;
    }
  }

  .ribbon-path-s4 {
    border: 0 none;
    background: none;
    height: 0;
    position: absolute;
    &.dotted {
      left: 188px;
      border-top: 1px dashed #ffffff;
      top: 60px;
      opacity: 0.5;
      width: 522px;
    }
  }

  .icon-Checkmark {
    position: absolute;
    width: 10px;
    height: 8px;
    top: 56px;
    left: 27px;
    opacity: 0;
  }
  .icon-littledood {
    position: absolute;
    width: 12px;
    height: 14px;
    top: 53px;
    left: 26px;
    opacity: 0;
  }
  .icon-milestone {
    position: absolute;
    width: 9px;
    height: 40px;
    top: 40px;
    left: 50px;
    opacity: 0;
  }

  .icon-newNowIcon {
    position: absolute;
    width: 45px;
    height: 25px;
    bottom: 88px;
    left: 12px;
    opacity: 0;
  }

  .icon-newTimeIcon {
    position: absolute;
    width: 56px;
    height: 26px;
    bottom: 55px;
    left: 90px;
    opacity: 0;
  }

  .ribbon-nowTime,
  .ribbon-nowTime2 {
    position: absolute;
    bottom: 88px;
    left: 7px;
    opacity: 0;
  }

  .ribbon-nowTime2 {
    left: 12px;
    opacity: 0;
  }

  .ribbon-travel-line2 {
    position: absolute;
    left: 24px;
    bottom: 14px;
    z-index: 2;
    width: 14px; // 67
    height: 36px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 0;

    .line {
      position: relative;
      width: 66px;
      height: auto;
    }
  }

  .icon-littlePlane {
    position: absolute;
    width: 20px;
    height: 11px;
    top: 53px;
    left: 110px;
  }

  .icon-landingLine {
    position: absolute;
    width: 55px;
    height: 173px;
    left: 134px;
    top: -112px;
    opacity: 0;
  }

  .icon-NowTimeLanding {
    position: absolute;
    width: 45px;
    height: 26px;
    top: -55px;
    left: 12px;
    opacity: 0;
  }

  .icon-whiteRing {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 55px;
    &.baggageClaim {
      left: 450px;
    }
    &.lyftService {
      left: 574px;
    }
    &.arriveatHome {
      left: 705px;
    }
  }
  .icon-baggageClaim {
    width: 80px;
    height: 26px;
    position: absolute;
    left: 415px;
    top: 0;
  }

  .icon-lyftService {
    width: 60px;
    height: 26px;
    position: absolute;
    left: 555px;
    bottom: -30px;
  }

  .icon-arriveatHome {
    width: 80px;
    height: 26px;
    position: absolute;
    left: 675px;
    top: 0;
  }

  .ribbon-travel-line {
    position: absolute;
    left: 27px;
    bottom: 13px;
    z-index: 2;
    width: 16px; // 155 | 192
    height: 36px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 0;

    .line {
      position: relative;
      width: 151px;
      height: auto;
    }

    .indicator {
      position: absolute;
      right: 0;
    }
  }

  .icon-TimeNowLast {
    width: 45px;
    height: 26px;
    position: absolute;
    left: 10px;
    top: 0;
    opacity: 0;
  }

  .icon-BaggageClaimWhite {
    width: 80px;
    height: 26px;
    position: absolute;
    left: 190px;
    top: 0;
    opacity: 0;
  }
}

.weather {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .cloud1 {
    position: absolute;
    width: 80px;
    height: 53px;
    top: 340px;
    right: -100vw;
    z-index: 3;
  }
  .cloud2 {
    position: absolute;
    width: 67px;
    height: 43px;
    top: 320px;
    right: -100vw;
  }
  .cloud3 {
    position: absolute;
    width: 80px;
    height: 53px;
    top: 400px;
    right: -100vw;
  }
  .cloud4 {
    position: absolute;
    width: 50px;
    height: 33px;
    top: 425px;
    right: -100vw;
    opacity: 0.6;
  }
  .cloud5 {
    position: absolute;
    width: 67px;
    height: 43px;
    top: 500px;
    right: -100vw;
    opacity: 0.6;
    z-index: 3;
  }
  .cloud6 {
    position: absolute;
    width: 81px;
    height: 53px;
    top: 240px;
    right: -9vw;
    opacity: 1;
  }
  .cloud7 {
    position: absolute;
    width: 67px;
    height: 43px;
    top: 285px;
    right: -20vw;
    opacity: 0.3;
  }
  .cloud8 {
    position: absolute;
    width: 40px;
    height: 26px;
    top: 240px;
    right: -22vw;
    opacity: 0.6;
  }
  .icon-moon {
    position: absolute;
    width: 37px;
    height: 52px;
    top: -100px;
    left: 45vw;
    opacity: 0;
  }
  .icon-sun {
    position: absolute;
    width: 110px;
    height: 115px;
    top: 160px;
    right: -250px;
  }
  .icon-star {
    position: absolute;
    width: 10px;
    height: 10px;
    opacity: 0;
    &.star1 {
      top: -200px;
      left: -300px;
    }
    &.star2 {
      top: -100px;
      left: -30vw;
    }
    &.star3 {
      top: -50px;
      left: -15vw;
    }
    &.star4 {
      top: 90px;
      left: -25vw;
    }
    &.star5 {
      top: 0;
      left: 40vw;
    }
    &.star6 {
      top: -135px;
      left: 50vw;
    }
    &.star7 {
      top: -145px;
      left: 30vw;
    }
    &.star8 {
      top: 200px;
      left: 55vw;
    }
    &.star9 {
      top: -140px;
    }
    &.star10 {
      top: 120px;
      left: 28vw;
    }
    &.star11 {
      left: -23vw;
    }
    &.star12 {
      top: 130px;
      left: 48vw;
    }
    &.star13 {
      top: 220px;
      left: 38vw;
    }
  }
}
