.kind-words {
  position: relative;
  overflow: hidden;
  background-color: #ebeaef;

  padding: 104px 0;

  @include bp(medium) {
    padding: 104px 0; //308
  }

  .pin {
    height: auto;
  }

  .unpin {
    padding-top: 186px;
  }

  &--trigger {
    position: absolute;
    display: block;
    top: 3.5%;
  }

  &--title {
    @include font-size(50px);
    font-weight: normal;
    font-family: "DIN";
    margin-top: 0;
    margin-bottom: 104px;
    color: $blue;
    line-height: 1;
    letter-spacing: -1.3px;
  }

  &--point2 {
    margin-top: -48px;
    opacity: 0;
  }

  &--point {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: custom-counter;

    &-trigger-2 {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: red;
      top: 100vh;
    }

    &-trigger-3 {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: red;
      top: 200vh;
    }

    li {
      position: relative;
      counter-increment: my-awesome-counter;
      padding-left: 32px;
      margin-bottom: 102px;

      &.active {
        p {
          opacity: 0;
        }
      }
    }

    p {
      @include font-size(15px);
      line-height: 1.6;
      letter-spacing: 0.75px;
      opacity: 1;
      transition: opacity 0.3s ease;
    }

    @include bp(medium) {
      padding-right: 0px;
    }
  }

  .kind-words--content-scroll {
    position: relative;
  }

  .fly-container {
    position: relative;
    top: 0px;
    width: 170%;
    padding: 0 0 100vh 0;
    margin: 64px 0 0 0;
    @include bp(medium) {
      margin-top: 208px;
    }
  }

  &--fly {
    position: relative;
    stroke-width: 25;
    // stroke-miterlimit: 10;
    stroke: $blue;
    fill: $blue;
    overflow: visible;
    display: none;

    // transform: scale(1) translate(-20px, 0);
    transform-origin: 0px 0;

    text {
      opacity: 0;
    }
  }

  &--with-us {
    position: absolute;
    top: 0;
    @include font-size(80px);
    font-weight: normal;
    font-family: "DIN";
    color: $blue;
    margin: 0;
    line-height: 1;
    opacity: 0;
    min-width: 480px;
    margin: -0.9rem 0 0 -0.5rem;
  }

  .point-large {
    &--title {
      font-family: "DIN";
      @include font-size(57px);
      line-height: 1;
      letter-spacing: normal;
      color: #00205b;
      margin-top: 64px;

      @include bp(medium) {
        @include font-size(80px);
      }
    }

    &--desc {
      margin-top: 40px;
    }

    &--first-point {
      margin-top: -1rem;
    }
  }
}

.icon-fly {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(4);
  transform-origin: top left;
  stroke-width: 3px;
  stroke-miterlimit: 30;
  stroke: #00205a;
  fill: #00205a;
}
